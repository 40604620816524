
import {Component, Vue, Watch} from "vue-property-decorator";
import store, {ACTIONS} from "@/store/store";
import {Getter, State} from "@/store/decorators";
import InfoCard from "@/components/InfoCard.vue";
import Button from "@/components/Button.vue";
import {Region, Stage, StageRegionCombination} from "@/types";

@Component({
    components: {Button, InfoCard},
})
export default class StatusBoard extends Vue {
    @State("matchMakerData") matchMakerData!: Array<any>;
    @Getter("relevantStageRegionCombinations") relevantStageRegionCombinations!: StageRegionCombination[];
    private selectedStageRegionCombination = 0;

    @Watch("matchMakerData")
    private updateSelectedStageRegionCombination(): void {
        this.selectedStageRegionCombination = this.relevantStageRegionCombinations.findIndex(
            (entry) => entry.stage === store.state.stage && entry.region === store.state.region,
        );
    }

    private async updateStageAndRegion(stage: Stage, region: Region): Promise<void> {
        await store.dispatch(ACTIONS.STOP_POLLING_MATCHMAKER);
        await store.dispatch(ACTIONS.START_POLLING_MATCHMAKER, {stage, region});
    }

    // Life cycle methods
    mounted(): void {
        // preselect stage
        const firstStageAndRegion = this.relevantStageRegionCombinations[0];
        this.updateStageAndRegion(firstStageAndRegion.stage, firstStageAndRegion.region);
    }

    beforeDestroy(): void {
        store.dispatch(ACTIONS.STOP_POLLING_MATCHMAKER);
    }
}
