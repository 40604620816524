import {PortalStage, Stage} from "@/types";

// returns a list of Stages that are allowed for a given PortalStage
export function mapPortalStageToDisplayedStages(portalState: PortalStage): Array<Stage> {
    switch (portalState) {
        case PortalStage.development:
            return [Stage.DEV, Stage.QA, Stage.BETA];
        case PortalStage.preprod:
            return [Stage.PRELIVE];
        case PortalStage.production:
            return [Stage.PROD];
        default:
            return Object.values(Stage);
    }
}

export function configFromQueryParameter(): any {
    const configString = new URLSearchParams(window.location.search).get("config");
    return JSON.parse(atob(configString!));
}
