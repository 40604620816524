import {
    base64LogoAudi,
    base64LogoBentley,
    base64LogoLamborghini,
    base64LogoMan,
    base64LogoPorsche,
    base64LogoSeat,
    base64LogoSkoda,
    base64LogoVW,
} from "@/assets/brand-logos";

export type BrandExample = {model: string; prCode: string};

export type BrandConfig = {
    name: string;
    base64Logo: string;
    examples: Array<BrandExample>;
};

export const kvpsToBrandConfigMap: Record<string, BrandConfig> = {
    A: {
        name: "Audi",
        base64Logo: base64LogoAudi,
        examples: [
            {
                model: "RS e-tron GT",
                prCode: "51962,F83RH7,2022,T9T9,KH,PC5,PEG,E0A,6H2,1PC,2DS,0GA,4UF,6XL,EA1,5SL,GM3,5RU,1X1,J9K,A8H,6K8,2EC,1LQ,6FJ,B0A,KB3,0VC,QJ0,C03,IT3,IU1,JE3,IW3,GW0,1BK,3S0,3FG,7AA,5MY,7M9,7X2,6A0,1P0,VF1,FP1,2V1,5L0,7G0,F0A,9R0,0YB,0JD,N03,8T6,VC0,G1X,GH3,A51,0N1,6NC,5KA,KH5,8IY,KS0,KM2,4L6,K8S,EK0,QR8,4D5,GB0,KA0,KK3,6P1,6W1,5K5,QQ2,NW1,NJ0,ES7,L0L,QZ7,8G4,70T,73P,76H,EH1,2PF,AV1,9VS,2C7,7HM,FN1,L1W,7UG,FZ0,GP0,FT0,AQ0,54C,9JA,ER1,7K3,HG1,1G8,4QE,4X4,A00,8VM,QL5,VI6,9P6,N0Q,3L4,4A3,0LW,JX0,8Y1,5XC,7Y0,6I3,4KC,FA0,2W0,4I3,9B0,B01,5D1,NT0,QV3,1EX,2Z8,1T3,GA2,Q1J,9ZX,EW0,4GF,2WA,0TD,4ZD,3A1,JS1,VH0,9M3",
            },
            {
                model: "e-tron",
                prCode: "51902,GENCAE,2022,2Y2Y,TJ,WQS,E0A,1PR,1TX,0GA,04A,1D0,4UF,QE0,GS0,6XD,EA1,5SL,GM4,5RU,0K9,1X1,J9T,9WC,A8Y,6K8,1KQ,J2D,1LP,6FA,B0A,KB3,1S2,0VC,7A0,C18,IT3,IU2,JE3,IW3,GW0,2MB,3S0,3FA,6F7,7AA,5TG,VT5,9X9,7W1,UF7,GZ0,0FA,6A0,8K4,FP1,2V5,7G0,F0A,9R0,0YQ,0JM,T4K,3U4,8T6,VC0,G1Z,A51,5J1,6NQ,9AK,8IY,KS0,4L6,K4H,QK1,6SS,0Y1,QR8,4D0,GB0,5ZF,5C0,3G4,KA0,KK3,31K,5K5,QQ0,NW1,NJ0,ES7,L0L,QZ7,8G9,70T,73P,76H,EH1,7P1,1XW,AV1,9VD,2C5,7HM,6E3,FM0,9S8,M1J,7UG,GP0,FT0,FQ1,AQ0,54L,9JA,ER1,7K1,HG6,1G8,4X3,QL5,4E7,VI9,2K7,2F0,1I0,N5K,3L3,4A0,0LW,JX0,8Y1,S3Y,5XC,7Y0,6I3,4KC,3Y0,7B3,GJ0,FA0,9PA,4I7,9B0,B01,5D1,3CX,QV3,1SA,1EX,2Z0,1T3,GA1,Q1D,9ZX,9TA,EW0,7E1,4GF,2WA,0TD,4ZP,3B3,JS0,LX2",
            },
            {
                model: "e-tron Sportback",
                prCode: "AUDI-E-TRON-S-SPORTBACK,GEASCE,2022,2D2D,MP,04A,0FA,0GA,0JN,0K9,0LW,0TD,0VC,0Y1,0YR,1D0,1EX,1G8,1I0,1KW,1LF,1PR,1S2,1SA,1T3,1TX,1X1,1XW,2C5,2F0,2K3,2MB,2V5,2WA,2Z8,31K,3B3,3CA,3FA,3G4,3PN,3S0,3U4,3Y0,4A3,4D3,4E7,4GF,4I7,4KC,4L6,4UF,4X3,4ZB,5C0,5D1,5J4,5K5,5RU,5SL,5TG,5XC,5ZC,6A0,6E3,6F7,6FC,6I3,6K8,6NQ,6SS,6XG,70T,73P,76H,7A0,7AA,7B3,7E1,7G0,7HA,7K1,7P1,7UG,7W1,7Y0,8G9,8IY,8K4,8T6,8Y1,9AK,9B0,9JA,9PA,9R0,9S9,9TA,9VD,9WC,9X9,9ZX,A51,A8F,AQ0,AV1,B01,B0A,C17,CA0,E0A,EA0,EH1,ER1,ES7,EW0,F0A,FA0,FN0,FP1,FQ0,FT0,G1Z,GA1,GB0,GJ0,GM4,GP0,GS0,GW0,GZ0,IT3,IU2,IW3,J2D,J9T,JE3,JS0,JX0,K5B,KA0,KB3,KK3,KS0,L0L,LX2,MQ3,N0K,NJ0,NW1,PS8,PV6,Q2J,Q73,QE0,QK1,QL1,QQ0,QR8,QV3,QZ7,T95,UF7,VC0,VI9,VT5",
            },
            {
                model: "A1",
                prCode: "50062,GBACFG,2022,2YH1,TV,PCE,WED,WQS,E0A,6H4,1PA,1TG,UG1,4BI,1D0,4UF,1E5,QE1,6XE,EA1,5SL,5RU,0K0,1X0,A9E,6K4,1KE,J2D,1ZK,6FX,0UL,B0A,1S2,1AS,0VC,2H4,QJ0,C23,0C0,IT3,3FA,GT6,UB2,6F7,G12,1Y3,EF1,0IJ,7AA,5MJ,VT5,7X2,7W0,UI2,0FD,4R4,6A0,VL6,VF2,8K4,F0A,8GU,0YN,L18,T2C,8T8,G1C,6PC,A51,5J3,8M1,0N2,6NQ,3NZ,9AK,8IT,U5A,4L2,K8R,QK2,1W0,0Y1,QR9,3Q6,GB0,5ZF,1Z0,A60,1A7,3G0,KA0,KK3,31K,5K7,3GD,QQ0,FU0,L0L,QZ7,3H0,8G1,7P4,1XX,AV1,9VS,2C2,1L0,7HA,8Q3,6E3,FM0,9S1,8Z5,IX1,MJ6,AW1,7UG,8TB,8WA,EL5,4P0,FQ0,AQ0,C2K,I8Y,9JA,ER1,7K1,H9J,V0A,1G8,0B1,3QT,NZ4,4X3,7F9,2KR,8VM,QI6,1I0,3ZU,N7K,3L3,4A3,0LW,8QM,7L6,QH1,7Y0,6I2,4KF,2JD,0AT,FA0,2UH,8X0,8N6,4I3,B01,5D1,0A2,QV3,1SA,1EX,2Z8,00A,1T3,Q4H,9ZX,EW0,QG1,7E0,4GF,8W1,8FC,2WA,0TD,4ZA,3A2,9M0",
            },
            {
                model: "RS 3 Sportback",
                prCode: "50362,8YARWY,2022,2Y2Y,TG,4G3,E0A,6H0,1PA,2CH,0P8,4BI,1D0,4UF,1E5,QE1,6XN,EA1,GM1,5JA,0K0,1X1,A8F,8J3,1KD,J1N,1LF,6FQ,NY0,B0A,1S3,1AQ,0VC,2H6,QJ1,C19,IT3,IU2,JE3,IW3,3S2,3FA,1JR,6F6,G15,EF1,0EX,7AA,7TN,VT2,7X5,7W0,6A0,VF1,8K4,FP1,UH1,5L1,F0A,8GV,0YG,L14,TM1,3V6,VC0,G1D,6Y2,A52,5J5,8M1,0N4,6NQ,3NT,9AK,8IT,KS0,20A,U5A,4L2,GN0,K8R,QK1,4H5,0Y1,QR7,3T2,GB0,5ZF,1Z2,0F1,5C0,KA0,KK3,31B,5K5,QQ2,NW0,NJ0,ES0,L0L,1N7,3H0,8G0,EV0,73A,76A,7P0,2PK,AV1,8RL,7HC,NM5,6E3,FN1,7J3,8Z5,IX1,D4I,7UG,FZ1,GP0,4P4,FQ1,AQ0,52V,I8Y,9JA,ER1,7K1,I86,V0A,1G8,0B1,NZ2,6C2,7F9,A00,2KR,8VM,QL5,4E0,QI6,2F0,1I0,N1H,3L3,4A0,0LW,7L6,8Y1,7Y0,6I3,7B3,2JP,0BJ,0AE,FA0,8X8,8N6,4I7,B01,5F1,NT0,QV3,1SA,1EX,2Z7,1T3,GA0,Q1D,9ZX,9TK,EW0,QG1,7E0,4GH,8FC,2WA,0TD,4ZP,6EA,3A0,9M0",
            },
            {
                model: "A4 Avant",
                prCode: "a4avant,8WDCZY,2021,LC_PR_AEEXC110,Q0Q0,EI,PR9,6Y1,HZ1,PXC,8VP,8IY,8G4,QK1,8X1,WQS,GT6,6NQ,VT5,6F7,VF1,Q1D,5TG,GB1,1XW,47E,N7V,9ZE,E0A,6H0,1PA,7IA,0P0,4BI,04A,1D0,4UF,QE0,6XD,EA0,5SL,6TS,0K4,1X1,9WC,A8Y,6K8,1KD,J2D,1LC,6FA,0UA,NY0,B0A,1S2,0VC,2H9,C49,0C0,IT6,IU2,JE3,IW3,1BE,3S2,3FA,2G1,7AA,7X0,7W0,0FA,6A0,VL1,8K4,FP1,UH1,5L0,7G0,F0A,9G9,0G7,0YJ,0JH,T2A,8T6,VC0,5A8,G1D,GH4,6V1,A51,5J0,3NT,9AK,KS0,U5A,4L2,K8D,6SS,0M2,4H3,0Y1,QR8,4D0,5ZF,1Z2,A61,1A2,5C0,3G0,KA0,KK3,6W6,5K5,3GD,QQ0,FU0,L0L,QZ7,3H0,7P0,AV1,8RM,7HA,NM1,6E1,FM0,9S5,8Z5,IX1,DF9,9K0,7UZ,FZ1,GP1,FQ0,AQ0,9JA,ER1,7K1,V0A,1G8,4X3,A00,2KR,FK0,QL1,4E0,QI6,2K7,2F0,9P6,3L3,4A0,0LW,7L8,8Y1,5XK,7Y0,6I0,4KC,3Y0,FA0,3M3,6JD,4I7,9B0,B01,5D1,NT0,QV3,1VG,1SA,1EX,2Z8,1T3,EW0,7S0,7E0,4GF,2WA,0TD,4ZE,6EP,3B3,0RZ,VH0,9M0",
            },
            {
                model: "RS 5 Coupé",
                prCode: "50702,F5PRXA,2022,6Y6Y,UB,PA2,PC3,PGD,PXB,PYG,PYH,WL1,YVA,E0A,6H0,1PD,7IB,0P0,4BI,04A,1D0,4UF,QE0,6XL,EA1,5SL,6TS,0K0,1X1,9WC,A8F,6K8,1KR,J1N,1LU,6FJ,0UA,NY0,B0A,1S2,0VC,2H1,C07,0C0,IT3,IU1,JE3,IW3,GW0,2MC,3S0,3FA,GT6,6F6,2G1,7AL,5MD,VT2,7X2,7W0,0FA,6A0,VL0,VF1,8K4,FP1,UH1,5L2,7G0,F0A,9G2,0G7,0YE,0JH,TV8,8T6,VC0,5A8,G1G,6Y4,GH2,6V1,A52,5J8,6NQ,3KA,9AK,8IZ,KS1,U5A,4L6,K8C,QK1,6SS,0M2,4H0,0Y1,QR8,4D0,GB0,5ZF,1Z6,A60,1A2,5C0,3G0,KA0,KK3,6W6,5K5,3GA,QQ1,FU0,L0L,1N8,3H9,8G4,8I6,1XP,AV1,9VS,7HD,NM1,6E3,FN1,9S7,8Z5,IX1,D4D,9K0,7UG,FZ1,GP1,FQ1,AQ0,CV6,9JE,ER1,7K1,H59,V0A,1G8,4X3,A00,2KR,8VP,FK0,QL5,4E2,QI6,VJ3,2F0,9P6,N1H,3L4,4A3,0LW,7L8,8Y1,S3G,5XK,7Y1,6I0,4KC,3Y0,FA0,8X1,3M3,6JD,4F2,9B0,B01,5D1,NT0,QV3,1VH,1SA,1EX,2Z7,1T3,Q4Q,9ZV,9TK,EW0,7S0,7E0,4GF,2WA,0TD,4ZD,6EA,3B3,0RZ,VH0,9M0",
            },
            {
                model: "A6 Avant",
                prCode: "50812,4A5BNY,2022,2Y2Y,MZ,PBY,PGC,WDC,E0A,1PA,1TN,0P0,4BI,1D0,4UF,QE0,GS0,6XK,EA1,5SL,GM4,6TS,0K3,1X1,9WC,A9D,6K8,2EF,J0V,1ZC,6FJ,B0A,1S2,4V0,0VC,7A0,2H9,C48,IT3,IU1,JE3,IW3,GW0,1BE,3S2,3FA,6F7,7AA,5TG,VT5,7X5,7W3,UF7,GZ0,0FA,6A0,1P0,VL1,VF1,6RA,8K4,FP1,2V5,5L0,7G0,F0A,9R0,8GZ,0YM,EP0,0JL,T4H,8T5,VC0,G1D,6Y2,GH4,6V1,A51,5J3,0N1,6NJ,3NT,9AQ,8IT,KS0,20F,U5A,4L6,K8D,QK1,0M2,0Y1,QR9,4D0,GB1,5ZF,1Z2,1A2,5C0,3G0,KA6,KK3,6W6,5K5,3GD,QQ2,NJ0,FU0,ES3,L0L,QZ7,8G4,70T,73P,76H,7P1,1XP,AV1,9VD,2C5,3HL,7HA,6E3,FM0,9S8,8Z5,IX1,M1H,7UG,FZ1,GP0,FT1,FQ0,AQ0,C1S,I8Z,9JA,ER1,7K1,H6M,V0A,1G8,4X3,7F9,A00,2KR,8SP,FK0,QL5,4E7,QI6,2K7,2F0,1I0,N5K,3L3,4A4,0LW,JX1,7L8,8Y1,5XC,7Y1,6I6,4KC,3Y0,GJ0,FA0,8X1,9PF,3M3,0NB,4I3,9B0,B01,5D1,3CA,NT0,QV3,1VG,1SA,1EX,2Z0,1T3,GA1,Q1D,9ZE,9TA,EW0,7E0,4GF,QW0,2WA,0TD,4ZP,6EA,3B3,0RZ,VH0,9M0",
            },
            {
                model: "RS 6",
                prCode: "rs6avant,4A5RCA,2021,2D2D,QN,A00,6A0,7A0,EA0,FA0,GA0,KA0,9B0,5C0,1D0,7E0,2F0,7G0,1I0,GJ0,NJ0,FK0,9M0,GM0,1P0,EP0,GP0,AQ0,ES0,FT0,NT0,FU0,4V0,EV0,EW0,QW0,VW0,3Y0,B01,5D1,QE1,VF1,QK1,VL1,FN1,7P1,FP1,FQ1,ER1,IU1,6V1,AV1,1X1,8X1,IX1,0Y1,8Y1,FZ1,A52,1A2,QQ2,1S2,VT2,3B3,6E3,JE3,VH3,5J3,VJ3,7K3,KK3,3M3,1T3,IT3,QV3,IW3,3G4,0K4,8K4,3L4,5K5,0M5,GS5,8Z5,6F6,QI6,4L6,6W6,1Z6,I57,2C7,4E7,C38,1G8,9G8,6K8,7L8,9S8,2Z8,7F9,2H9,20A,B0A,E0A,F0A,V0A,73A,U5A,76A,3CA,6EA,0FA,0NA,9PA,1SA,2WA,6RB,0VC,9WC,J2D,K8D,5MD,0TD,A8F,4GF,4UF,5XF,0YF,5ZF,G1G,7UG,M2I,4BI,1VJ,0JK,9TK,L0L,7AL,5SL,T1M,1TN,Q4Q,9AQ,2KR,1PR,6TS,3NT,0LW,1EX,I8Z,2PZ,0RZ,PA3,0N5,6Y7,1N8,GH2,PXF,8G5,8IZ,8SQ,PEH,N5M,4D3,7HF,0P6,2MC,2V9,3FU,3GN,3HB,4A4,4X9,6FT,6NA,8RF,6XL,9JC,9R1,9ZE,GB1,GZ2,KS1,PCC,6I6,8T5,QR9,PCM,7W3,JX1,7Y1,9X9,PGB,4F2,PQZ,3S3,4ZL,5L2,UF8,VC2,53G,PC6,1KY,1LM,QL5",
            },
            {
                model: "A7",
                prCode: "a7sb,4KA0LA,2023,A2A2,FZ,E0A,1PA,1TP,0P0,4BI,1D0,4UF,QE0,GS0,6XD,EA0,5SL,GM0,6TS,0K4,1X1,9WC,A8S,6K8,1KF,J2D,1ZA,6FA,B0A,1S2,0VC,7A0,2H9,C62,IT3,IU0,JE3,IW3,GW0,1BA,3FA,6F0,7AA,5MA,7M1,7X0,7W1,UF7,GZ0,0FA,6A0,1P0,VL1,VF0,8K4,FP1,2V5,5L0,7G0,F0A,9R0,9G8,0YE,EP0,0JM,T2P,8T6,VC0,G1G,6Y2,GH1,6V1,A51,5J2,0N1,6NJ,3NZ,9AK,8IT,KS0,20A,U5A,4L2,K8R,QK1,0M0,0Y1,QR8,4D0,GB0,5ZF,1Z2,0F3,5C0,3G0,KA0,KK3,6W6,5K5,3GA,QQ0,NJ0,FU0,ES0,L0L,QZ7,8G0,EV0,73A,76A,EH0,7P0,2ZB,AV1,8RM,2C5,3HL,7HA,6E3,FM0,9S7,8Z5,IX2,DV8,7UG,FZ1,GP0,FT0,FQ0,AQ0,C7Z,I8Z,9JA,ER1,7K1,I54,V0A,1G8,4X3,7F9,A00,2KR,8SK,FK1,QL1,4E7,QI6,2K1,2F0,1I0,N2M,3L3,4A0,0LW,JX0,7L8,8Y1,5XC,7Y0,6I3,4KC,3Y0,GJ0,FA0,8X0,9PA,3M3,0NA,4I7,9B0,B01,5D1,NT0,QV3,1VH,1SA,1EX,2Z8,1T3,GA0,Q1A,9ZX,9TA,EW0,7E6,4GF,2WA,0TD,4ZB,6EA,3B3,0RZ,VH2,9M0",
            },
            {
                model: "RS 7",
                prCode: "50981,4KARCA,2021,2Y2Y,QH,AV1,A00,2WA,9AQ,7Y1,4E7,8Y1,B01,7L8,4GF,9R0,0TP,5K5,6EA,ES0,1TP,6NA,FA0,QE1,6I5,7HM,6E3,PXC,7AL,20A,GB1,PCB,AQ0,M2I,9S8,E0A,1S2,4UF,4ZP,4D3,1X1,F0A,PC8,VJ3,2C7,1SA,0K4,4L6,2KR,3FA,KA2,4A4,GJ0,7E0,1N8,PCM,G1G,7G0,GS5,0RZ,VL1,IT3,8IZ,0NA,0P6,1LV,QV3,IX1,8RF,0N5,1I0,1EX,V0A,FU0,3M3,EV0,IU1,VH3,FQ1,UF8,1PR,2PZ,6TS,GP0,PEG,4F2,FP1,9TK,76A,T1M,8X1,7UG,8T6,VT2,7F9,1P0,L0L,6XL,9M0,FZ5,EW0,FK0,QK1,1Z6,NJ0,6Y7,B0A,0LW,A52,7P1,9WC,6FJ,5ZF,7A0,7X2,0VC,3Y6,5D1,7K3,IW3,A8F,PEH,0Y1,9G8,QQ2,JX1,KK3,9B0,1T3,2H9,VW0,3G4,1A2,6K8,GZ2,6A0,3GA,GM0,5C0,73A,Q4Q,8SQ,VF1,6V1,5XF,N5M,8Z5,KS1,4X9,GH2,0M5,K8R,J2D,9JC,52F,NT0,1D0,7W3,QL5,PGB,QR9,GA0,FN1,5MD,VC2,3NZ,I8Z,3L4,2Z8,1G8,5L7,U5A,0FA,EP0,6W6,6F6,FT0,2MC,9ZE,9PA,EH0,2V5,5SL,8K4,ER1,JE3,3HB,8G5,2ED,2F0,PA3,3B3,QI6,C43,1VJ,EA0,4BI,5J2",
            },
            {
                model: "SQ2",
                prCode: "sq2,GAGS3Y,2022,5H5H,RA,A00,6A0,EA0,FA0,0C0,5C0,FC0,7E0,7G0,3H0,0K0,9K0,QK0,9M0,FN0,AQ0,FQ0,3S0,7S0,ES0,KS0,NT0,EV0,1W0,EW0,8X0,B01,C21,A51,5D1,0F1,EF1,QG1,7K1,UK1,8M1,0P5,GP1,HP1,ER1,9U1,6V1,AV1,8W1,1X1,IX1,0Y1,8Y1,0A2,0B2,3T2,1Z2,KK3,1T3,QV3,4X3,3B4,8K4,0N4,4R4,DS4,NZ4,UB5,1E5,5K5,VT5,8Z5,G36,2H6,QI6,7L6,VL6,4P6,6W6,L17,6F7,1N7,1U8,2Z8,7F9,9P9,20A,B0A,E0A,F0A,V0A,U5A,3CA,0FA,9JA,1SA,0UH,2WA,7HB,8TB,0YB,0VC,G1D,0TQ,0AE,A8F,5ZF,0BG,0MG,K4H,Q4H,4GH,4BI,0IJ,9AK,L0L,5SL,6NQ,2KR,J0S,1AS,6SS,8GU,5RU,T3W,0LW,1EX,40S,YNH,5J5,PWS,QJ1,3L3,6E3,N3M,VF1,YLD,1XX,PYJ,6I6,8T8,6K4,9S8,QR9,I8L,7UG,9VS,EL5,IT3,PAI,6FJ,6H1,4ZD,PYB,7P1,6XK,4L6,PYF,1S1,1G5,PYI,UH2,KA2,PYL,7W1,7Y1,PYM,3NT,PYN,7AL,1PE,4F2,VW1,PXC,8VM,8IY,8G4,3FB,1M5,7TM,QQ2,QE7,4A3,PID,4UF,4H5,3G4,PC7,2ET,1LJ,7X5,4E7,2JU,9ZE,GB1,UI2,YNY,E0L,YIX",
            },
            {
                model: "Q4",
                prCode: "51272,F4BAF3,2022,2Y2Y,AR,PWM,PXC,PYC,PYG,PYM,PYU,PYY,YYB,4G3,E0A,UD0,1PA,2DV,0GA,1D0,4UF,QE0,6XI,EA1,GM1,5JA,0K9,1X1,J9D,A9F,8J3,1KB,J2S,1LC,6FJ,B0A,KB3,1S2,0VC,2H0,QJ1,C01,IT3,IU0,GW0,3S2,LH3,3FA,GT6,1JC,6F7,G02,7AA,5TG,VT5,7X2,7W1,0FA,6A0,VF1,9I5,FP0,F0A,5LV,8GJ,0YH,L17,N23,8T8,VC0,G1Z,A51,8M1,6NQ,3NT,KH7,8IY,KS0,4L6,K8G,4H5,0Y1,QR9,GB0,5ZF,5C0,KA6,KK2,31K,5K0,FB5,3GA,QQ9,NW0,NJ0,FU0,ES7,L0L,1N7,AI9,8G4,EV0,73A,76H,7P1,2FS,AV1,9VS,7HC,6E3,FM8,AI1,9S1,L1G,7UG,EL5,GP2,99E,99U,FT0,7PB,98R,98G,98C,99I,FQ1,AQ0,1NL,C7A,9JA,ER1,7K1,80V,3QH,NZ2,6C2,8VP,QL5,4E7,G9H,VI6,9P4,N5J,3L5,4A3,0LW,8Y0,79H,6I6,4KC,7B3,2JG,0BD,0AH,FA0,V6C,8X8,4I7,B01,5F1,3CA,QV3,1SA,1EX,2Z8,1T3,1J2,GA2,Q1D,9ZX,EW0,4GW,2WA,0TD,4ZD,3A2,LX0,9M3",
            },
            {
                model: "RS Q3",
                prCode: "rsq3,F3NRWY,2021,Y1Y1,AR,A00,6A0,EA0,FA0,7B0,5C0,4D0,7E0,3H0,1I0,0K0,3M0,9M0,GP0,AQ0,3S0,NT0,EV0,EW0,9Z0,B01,5D1,EF1,VF1,QG1,QH1,5J1,QJ1,QK1,UK1,GM1,TM1,FN1,7P1,FQ1,ER1,AV1,7W1,8W1,1X1,8X1,IX1,0Y1,8Y1,A52,6K2,GN2,2P2,3T2,VT2,7X2,1Z2,G13,C23,L33,0B3,KK3,8Q3,1S3,1T3,QV3,3B4,8K4,NZ4,1A5,1E5,4H5,5K5,5L5,7Y5,8Z5,6F6,QI6,7L6,VL6,8N6,GT6,J07,1N7,2Z7,1G8,1U8,8T9,20A,B0A,E0A,F0A,V0A,73A,K5A,U5A,76A,3CA,0FA,3FA,1PA,1SA,2WA,8WA,31B,8TB,1VB,8FC,0VC,9WC,G1D,0RE,A8F,4GF,5ZF,0BG,D4I,4BI,6FJ,2PK,L0L,0AL,1NL,5SL,8VM,J1N,2JQ,6NQ,0YQ,2KR,5KR,1AS,1TS,5RU,3ZU,8GV,0LW,0EX,1EX,42V,7K3,UI2,EL5,9S8,PXC,8JT,8G4,QL5,5MB,QQ2,KH5,6E3,9JC,9VS,QE1,UH2,UG5,KA2,PID,4UF,3G4,1M5,PS6,N1H,Q4Q,3L5,4A3,4ZP,PG3,7AL,4F2,VC2,4E7,IT3,9ZE,GB1,0P6,NM5,6Y4,PC3,1KF,1LK,PDE,2H4,1JT,4X4,4L6,6XK,PNB,QR9,7UG,I8Y,PEJ,0TP,7HH,FZ4",
            },
            {
                model: "Q5",
                prCode: "q5sb,FYTC1Y,2022,2D2D,EI,A00,C30,A60,6A0,EA0,FA0,9B0,0C0,5C0,VC0,1D0,4D0,7E0,2F0,VF0,3G0,7G0,VH0,NJ0,FK0,9M0,FM0,1P0,AQ0,FQ0,3S0,FU0,6W0,EW0,3Y0,VY0,B01,A51,I81,GA1,5D1,2G1,7K1,QL1,VL1,FP1,GP1,ER1,NT1,6V1,AV1,1X1,IX1,0Y1,8Y1,1A2,2P2,1S2,3B3,JE3,4H3,5J3,0K3,KK3,7L3,3M3,ES3,1T3,QV3,IW3,4X3,GH4,8K4,0M4,GM4,3U4,5K5,QT5,8Z5,QI6,9P6,1Z6,4E7,0G7,2K7,QZ7,1G8,7F9,2H9,6K9,B0A,E0A,F0A,V0A,U5A,0FA,6FA,1PA,1SA,2WA,1ZA,4ZB,0VC,G1D,J2D,3GD,0TD,20F,4GF,1KF,4UF,1VG,M1H,T4H,76H,4BI,7IJ,5XK,L0L,5SL,0YL,0JN,73P,9AQ,K8R,2KR,3NS,6SS,6TS,70T,0LW,1XW,3CX,1EX,A8Y,8GZ,0RZ,V71,WQS,GT6,6NQ,VT5,6F7,Q1D,N2R,5TG,7HB,5ZC,PV3,6XL,3L4,6E3,QE1,IU1,9JE,KS1,UH2,PX6,8IY,8G4,QK1,8SC,8X1,QQ2,2C7,9VS,3FU,PG3,7AL,4F2,VW5,8I6,1BK,4L6,9S8,GB1,4A3,7UG,IT3,9ZE,PCZ,7W3,7X5,7Y1,KA6,PCN,6I1,8T8,QR9,4M9,2Z7",
            },
            {
                model: "RS Q8",
                prCode: "rsq8,4MNRV2,2021,6Y6Y,AR,A00,6A0,7A0,EA0,FA0,GA0,9B0,5C0,1D0,2D0,7E0,2F0,7G0,EH0,1I0,GJ0,NJ0,FK0,VL0,GM0,GP0,AQ0,9R0,ES0,FT0,NT0,6U0,EV0,EW0,NY0,B01,C51,5D1,VF1,2G1,FN1,7P1,FP1,FQ1,ER1,6V1,AV1,8X1,IX1,0Y1,8Y1,A52,1A2,KA2,0E2,UH2,2P2,QQ2,1S2,VT2,3B3,JE3,VH3,7K3,KK3,3M3,1T3,IT3,QV3,IW3,5J4,0K4,8K4,5K5,0M5,0N5,GS5,QT5,8Z5,6E6,6F6,QI6,4L6,6W6,1Y6,2C7,4E7,2Z7,QZ7,1G8,9G8,7L8,9S8,J39,2H9,2V9,9X9,20A,B0A,E0A,F0A,V0A,73A,K5A,U5A,76A,0FA,3FA,9JA,0NA,9PA,1SA,2WA,2MB,0VC,9WC,J2D,9AE,A8F,4UF,5XF,5ZF,G1G,0YH,M2I,4BI,1VJ,9TK,L0L,04L,7AL,5SL,T1M,0JN,3PN,1XP,2KR,1TR,3NS,6TS,8IT,0LW,3CX,1EX,0RZ,PEH,N5M,Q4Q,4D3,3Y6,PA3,1P7,6Y7,GH2,PXC,8G5,8SQ,5MH,VJ3,1PR,6XL,6FQ,3S2,PG3,3U7,4F2,GZ2,VC2,4GR,9ZE,GB1,VW0,QL5,PQA,5L3,FY5,4ZP,4A4,PL2,6NS,PK6,1Z6,9M9,3GN,6SJ,UF8,9VS,IU1,PCM,6K8,7W3,JX1,QK1,7Y1,PCC,6I6,8T5,QR9,7UG,KS1,PC9,2EN,1LI,0P6,3G4,4X4,PEF,0TP,7HM,FZ4,52R",
            },
            {
                model: "TTS Roadster",
                prCode: "ttrsroadster,FVRRSY,2022,R1PA,JN,PA1,6XK,4A3,PC2,1ZK,1KX,PG3,7AL,4F2,PNQ,I8H,7UG,UJ1,PU7,4L6,PXC,8WM,8Q5,8IT,8G4,1PC,0P6,QE1,UH2,8T6,6Y9,QR9,GB1,KA2,9VS,6E3,9K1,EL3,CT1,8SC,N1H,3PK,2Y2,9ZE,E0A,7ZC,4BF,4UF,1E5,GS3,EA0,5SL,5RU,1X1,A8F,J1N,6FJ,0UN,NY0,B0A,1S2,LB0,0VC,2H6,C40,0C0,1BD,6F6,3X0,EF0,0EX,5MD,7M7,7X2,UE7,6A0,VL1,8K1,5L3,7G0,F0A,8GV,0G7,0YH,0JJ,TM1,VC0,G1D,6V0,A52,5J2,9AK,FC1,U5A,K8J,QK1,0Y1,1Z2,A60,1A7,5C0,3G2,KK3,6W6,5K5,QQ1,L0L,7P1,2ZC,AV1,7HA,NM1,FN1,9S8,8Z5,IX1,D4I,FZ1,GP1,FQ1,AQ0,9JA,ER1,7K1,HZ1,V0A,1G8,NZ0,4X1,A00,2KR,QI6,VJ3,9P6,0LW,7L6,8Y1,7Y0,6I0,4KC,FA0,8X0,8N6,0NA,B01,5D1,NT0,QV3,1VB,1SA,1EX,2Z8,1T3,Q4Q,EW0,7S1,7E0,4GF,2WA,4ZE,0RZ",
            },
            {
                model: "R8 Spyder",
                prCode: "AUDI-R8-SPYDER,4SRRAF,2022,8RPA,KG,0JZ,0K0,0LW,0M1,0P9,0U0,0UA,0VC,0Y1,0YZ,1A2,1BH,1E1,1EX,1G8,1KY,1LJ,1PD,1S2,1T3,1VN,1X2,1Z0,2KR,2WA,2ZC,3G0,3PR,4A3,4BI,4F2,4GQ,4L6,4UE,4X1,4ZP,52L,5C0,5D7,5J0,5K4,5L1,5MA,5RU,5SL,6A0,6FJ,6H1,6NZ,6V1,6W1,6XK,7AL,7G0,7HD,7K3,7L8,7M9,7S1,7UG,7X2,7ZG,8G1,8GH,8IT,8K1,8SP,8T2,8Y1,8Z5,9AK,9JC,9P3,9S8,9VS,9ZE,A00,A52,A60,A8F,AQ0,AV1,B01,B0A,C34,DL6,E0A,EA0,EL3,ER1,EW0,F0A,FA0,FC1,FM0,FR8,G2K,GB1,GP1,GT0,I04,I8H,IU0,J0B,K8W,KA2,KK3,L0L,LB0,N1H,NM1,NT0,NY0,PC2,Q1D,QE0,QI6,QJ0,QQ1,QV3,QZ7,TT8,U5A,UI2,VC1,VF2,VG6,WL2,YJH",
            },
        ],
    },
    C: {
        name: "SKODA",
        base64Logo: base64LogoSkoda,
        examples: [],
    },
    E: {
        name: "Bentley",
        base64Logo: base64LogoBentley,
        examples: [],
    },
    L: {
        name: "Lamborghini",
        base64Logo: base64LogoLamborghini,
        examples: [
            {
                model: "Aventador SVJ",
                prCode: "Lambo,4720GF,2022,F14 Q0A5,F56,99,GPC1PC1,GW0PW0P,GWV3WV3,GZDEZDE,JAZDGP,JAZEZZ,JAZHZZ,JAZMSH,JBZAAZ,JCANAZ,JCONSH,JCRBZZ,JDORSH,JFBCAZ,JFCCBU,JFCIAZ,JFCUBY,JFTAEK,JFWIZZ,JFZHZZ,JHDLSH,JRFFXX,JSBTNA,JSEISH,JSTCZZ,JSTXZZ,JWCRAZ,MAAUE0A,MABO6H4,MABR1PH,MAED7LS,MAER0P0,MAGM7CA,MAIB4UF,MALGQE0,MASE6XE,MASGEA0,MASL5SJ,MASR5RU,MAUD9WA,MAUSA8S,MBAH2EM,MBAV1LK,MBBO6FE,MBEH4M0,MBFA0UP,MBLBB0A,MBLEKN3,MBOW1S0,MBTA7YF,MCOCC23,MCOZ0C0,MCPDIW0,MDAE1BA,MDEI3FA,MDEKGT0,MDFO6F5,MDPF4XG,MEDW7AL,MEIH7TH,MEIKQ9B,MEIUQ8G,MEMINR7,MEPH7X0,MFEU6A0,MFIT6RC,MFLS8K4,MFVS7G1,MFZSF0A,MGMOT4Q,MGNF4U0,MGRTVC0,MGRV5A4,MGSPG1H,MHAK4Z0,MHES5J9,MHFE4HM,MHIA0N5,MHIM6NN,MHSW8ED,MINSU0F,MINT4N6,MIRS4L3,MKAE0KA,MKARK8Q,MKBV0M5,MKRS1A0,MKSA5C0,MKSI3G0,MKUHKK3,MKZH6P1,MKZV6W1,MLAC5K2,MLAKFB7,MLCPQQ0,MLEAL0L,MLEN1N8,MLFFVK5,MLRA1XR,MLSE8RE,MLTU3HK,MLUM7HB,MMADNM7,MMFA9Q1,MMIK3D7,MMJZIY1,MMOTMJ9,MNAV7Q2,MPAMGP4,MPRSFR8,MQUAAQ0,MRADCT9,MRAO8AJ,MRAU9JA,MRCOED0,MRDK7K1,MREIH7E,MRER1G8,MSAANU1,MSAD2HB,MSFS2K0,MSIBN6D,MSIE3L5,MSIH4A3,MSLE8F0,MSMAFV0,MSMEGC3,MSNA7L6,MSSL4B1,MSSP05Z,MSVOFA0,MSZU0NA,MTPLB01,MTRF5D7,MTSPNT0,MTSV3LX,MTVEQV3,MTYP1EX,MVBK1T0,MVGP5GZ,MVOSQ1D,MVTV9W0,MWABVD0,MWIN7S0,MZBR2WA,MZFM0TF,MZIE4ZE",
            },
            {
                model: "Huracan",
                prCode: "Huracan,4T31DE,2022,MAPPGS0,MRAOI8N,MSTD7B2,MTVEQV3,MSTF2JT,MRAU9JA,JMHDNA,MDFO6F3,MBBO6FA,MCDR7QA,JSTXNA,MKUHKK3,MEIH5TC,MLWR8Q0,MEIKQ9B,MGMOTT8,MTWL1VN,MEIL7M1,MBTA7YF,MMIK3I1,MEIUQ8I,MRST0E1,MRSV3QB,MAAUE0A,JARFMH,JSEINA,MFLA6LG,MGNF4U0,MRCOER1,JRCSNH,MIRS4L6,MFLS9C4,MSVOFA0,MABO6H5,MABR1PF,MASE6XE,MASGEA0,MRDK7K3,MASL5SJ,MMJZIY1,F14 Q0D2,MASR5RU,MSFS2K1,MBEH4M0,MLIA8G0,MASY0K0,MTYP1EX,MATA1X1,MSWR8X0,MSGK9P1,MMKU8Z5,MREIH25,GW0LW0L,MCHA2H7,MPAMGP4,MRER1G8,GYCSYCS,MCHRQJ0,MBWD9T1,JSHDNA,MAUD9WA,MPRSFR9,JRFFXX,MQUAAQ0,MAUSA8S,MZFM0TC,MEMINR1,MAED7ZH,MSIBN5W,MHSW8EX,MSIE3L5,MAER0P0,MSIH4A3,MGRA8T2,MKZH6P1,MVOSQ1D,MTKV4I2,MSIV5DA,MAFHUG1,MKZV6W1,MGRTVC0,MWAREW3,MGRV5A4,MSZR2Y0,JHDLMH,MGSPG1K,MAGM7CA,JBZAMH,MMOTDN3,MWSS4GE,MHES5J5,MZIE4ZE,MEPH7X2,MSKT5Y0,MNAV7Q2,JFBCNA,JWCRMH,MSLE7R3,MGDM3W6,MFSBUH1,MKLZ0Y1,MAIB4UF,GZDEZDE,MVBK1T0,MODTNQ4,JDORMH,JFCCTP,JAZAZZ,JAZDZZ,JAZEZZ,JFCIMH,MGEN8GH,MMADNM2,JFTAMH,JAZMNH,MBLBB0A,JFCUIN,MMRB9L1,MMAS6E3,MSNA7L8,MTPG9B0,MSNH8Y1,MTPLB01,MCOCC00,MSNRSK1,MAKB1E4,MVTV9ZX,MHIA0N5,JCONSH,MESSUF7,MDAA2S1,MDAE2MN,MAKS3Z4,MHIM6NN,MLAC5K2,MCPBIU2,MCPAIT0,MCPDIW0,MFEU6A0,MLAKFB7,JDAUNH,MLRA1XW,MALGQE3,JGHAZZ,MFVS7G1,MTRF5D7,MLRVAV1,MEDW7AL,JFWIDO,MMDSFM0,MHKA9AK,MLSE8RE,F56     99,JLBWZZ,MBOW1S1,MKAE0KA,MTSPNT0,JCANMH,MSAA5E6,JCRBGW,MTSV3LS,MLCPQQ0,MKARK8C,MWIN7S0,JCRPZZ,MSAU2KS,MMFA9Q1,MKRQ0U6,MLTU3HD,MKRS1A2,MINT4N5,MINSU0C,MKSA5C0,MKBV0M1,MKSI3G0,MSBR8SA,JSBTNA,MLUM7HB,MGKH0YZ,MBAH2EM,MLEAL0L,MDEKGT0,MKSUKA0,JFZAZZ,MSSL4B1,MSSP05Z,MGKV0JZ,MRADCK6,MLEN1N8,MBATJ0B,JFZHZZ,MBAV1LK,JFZJZZ,MFZSF0A",
            },
        ],
    },
    M: {
        name: "MAN Trucks&Bus",
        base64Logo: base64LogoMan,
        examples: [],
    },
    N: {
        name: "VW Nutzfahrzeuge",
        base64Logo: base64LogoVW,
        examples: [],
    },
    P: {
        name: "Porsche",
        base64Logo: base64LogoPorsche,
        examples: [],
    },
    S: {
        name: "Seat",
        base64Logo: base64LogoSeat,
        examples: [
            {
                model: "FORMENTOR",
                prCode: "FORMENTOR,KM7RZT,2023,4U4U,HH,05Z,0AL,0B3,0BK,0EX,0F1,0FA,0K0,0N4,0NA,0TA,0VA,0Y1,0YB,1AQ,1D0,1E5,1EX,1G8,1J2,1KD,1LF,1PA,1Q0,1S3,1SA,1T0,1X1,1Y3,1Z0,20A,2A0,2CY,2F0,2I0,2JY,2KY,2PZ,2WA,2Z3,31F,3A2,3C7,3CA,3FA,3GN,3H0,3J9,3LM,3NU,3PH,3QC,3S2,3T2,3W0,3ZU,4A3,4BI,4D0,4E6,4G0,4GW,4K5,4KF,4L6,4P0,4R4,4U5,4UF,4ZA,5A5,5C0,5D1,5J1,5JB,5K0,5MA,5XJ,6A0,6C2,6E3,6F3,6FH,6I6,6NL,6Q0,6XQ,76A,77G,79H,7AA,7B0,7E0,7G0,7J2,7K1,7L6,7P7,7T7,7W0,7X5,8AW,8FC,8G4,8GV,8IV,8J3,8M1,8N6,8Q3,8RL,8S4,8T8,8TB,8VM,8WB,8X6,8Y0,8Z4,8ZQ,9I5,9IJ,9JA,9M0,9P4,9T0,9WY,9Z3,A23,A9R,AV1,B00,B0A,C16,D4Y,E0A,EA0,EL0,EM0,ER1,EV0,EW0,F6J,FA0,FC0,FN1,G05,G1D,GB1,GM1,GP0,GW0,H2G,J1N,K8P,KA6,KH7,KK3,KS0,L0L,L13,N3C,NY0,NZ0,PCD,PLW,Q1D,Q9B,QG1,QH1,QI0,QJ1,QK1,QN2,QQ9,QR9,QV0,QZ0,TM1,U5A,U9C,UB9,UH2,UK3,V0A,V97,VF1,VH0,VT2",
            },
        ],
    },
    V: {
        name: "Volkswagen",
        base64Logo: base64LogoVW,
        examples: [
            {
                model: "ID.5 red",
                prCode: "DER-NEUE-ID.5-GTX-4MOTION,E399TN,2023,P8A1,UG,0AG,0BC,0FG,0GA,0HZ,0NB,0RH,0TA,0VC,0Y1,0YJ,1D0,1EX,1J2,1JP,1KB,1LC,1N7,1NL,1PE,1R3,1S2,1SB,1T9,1X1,2F0,2FM,2I0,2JC,2WA,31K,3A2,3CA,3D2,3FA,3GA,3J4,3LF,3NU,3QH,3S0,3T2,4A3,4G3,4GW,4K6,4KF,4L6,4N3,4S1,4UF,55L,5C0,5F1,5I6,5JA,5K7,5LV,5XJ,6A0,6C2,6F5,6FG,6H0,6I6,6M0,6XQ,76C,7AL,7E7,7G0,7K1,7PB,7UJ,7W2,7Y1,8A2,8AV,8G4,8GJ,8I6,8IV,8J3,8M0,8N6,8RT,8T8,8VP,8X7,8Y0,8ZQ,9M0,9P4,9T1,9WJ,9Z0,9ZX,A9Q,AV1,AY0,B01,B0A,C09,E0A,EA0,EL5,EM1,ER1,ES7,EV0,F0A,FT4,G03,G1Z,G9H,GJ0,GM1,GP3,GW2,HD5,IG0,IN0,J1H,J9D,JX0,K8C,KA6,KB3,KH4,KK3,KS0,L07,L0L,L1G,LH3,LX2,N23,N2S,NI0,NZ4,PBK,PJ6,PSF,QH1,QQ9,QR9,QV3,QW5,U5A,U9E,UD2,V0A,V6H,VF4,VI6,WD3,ADDGEO_ON",
            },
            {
                model: "ID.5 blue",
                prCode: "33330,E393HN,2023,2PA1,CP,PBD,4G3,E0A,UD0,6H0,1PE,0HZ,0GA,1D0,4UF,4S1,8ZQ,6XP,EA0,GM1,5JA,1X2,J9D,9WJ,A8C,8J3,1KB,J1H,1LC,6FG,B0A,KB3,1S3,0VC,9Z0,9T0,2H5,C09,GW2,3S0,LH3,3FA,1JA,6F2,G01,NI0,7AA,7X2,7W0,U9G,0FG,6A0,VF4,AY0,7G0,F0A,5LD,8GJ,0YJ,L05,N06,6M0,8T8,G1Z,8M0,IN0,3NU,KC0,8IR,KS0,U5A,4N3,4L6,K8C,0Y1,QR9,3T2,5ZF,5C0,KA0,KK3,31K,5K7,3GA,QQ9,ES7,L0L,1N3,IG0,8G0,EV0,76C,7P0,2FM,AV1,8RT,3D1,EM1,L1T,7UJ,EL5,GP3,FT0,7PB,1NL,U60,8AV,ER1,7K1,80S,V0A,3QH,NZ4,6C2,8VG,4E0,G9B,VI6,2F0,9P4,N4A,3L3,4A0,JX0,8Y0,5XJ,QH1,7Y0,6I1,4KC,2JB,0BC,GJ0,0AG,V6H,8X6,8N5,0NB,4I7,B01,5F1,3CA,3LJ,QV3,1SA,1EX,1T9,1J2,9ZX,7E7,4GF,QW6,2WA,0TA,3A2,LX1,0RH,9M0",
            },
            {
                model: "ID.5 white",
                prCode: "33330,E393HN,2023,2YA1,CP,PBD,4G3,E0A,UD0,6H0,1PE,0HZ,0GA,1D0,4UF,4S1,8ZQ,6XP,EA0,GM1,5JA,1X2,J9D,9WJ,A8C,8J3,1KB,J1H,1LC,6FG,B0A,KB3,1S3,0VC,9Z0,9T0,2H5,C09,GW2,3S0,LH3,3FA,1JA,6F2,G01,NI0,7AA,7X2,7W0,U9G,0FG,6A0,VF4,AY0,7G0,F0A,5LD,8GJ,0YJ,L05,N06,6M0,8T8,G1Z,8M0,IN0,3NU,KC0,8IR,KS0,U5A,4N3,4L6,K8C,0Y1,QR9,3T2,5ZF,5C0,KA0,KK3,31K,5K7,3GA,QQ9,ES7,L0L,1N3,IG0,8G0,EV0,76C,7P0,2FM,AV1,8RT,3D1,EM1,L1T,7UJ,EL5,GP3,FT0,7PB,1NL,U60,8AV,ER1,7K1,80S,V0A,3QH,NZ4,6C2,8VG,4E0,G9B,VI6,2F0,9P4,N4A,3L3,4A0,JX0,8Y0,5XJ,QH1,7Y0,6I1,4KC,2JB,0BC,GJ0,0AG,V6H,8X6,8N5,0NB,4I7,B01,5F1,3CA,3LJ,QV3,1SA,1EX,1T9,1J2,9ZX,7E7,4GF,QW6,2WA,0TA,3A2,LX1,0RH,9M0",
            },
            {
                model: "Taigo",
                prCode: "DER-TAIGO,CS14NZ,2023,W9A1,ED,00B,0A2,0AT,0B1,0EG,0FB,0K0,0N6,0NB,0P4,0TD,0VC,0Y1,0YN,1A7,1AS,1D0,1E5,1EX,1G8,1J2,1KV,1N1,1NL,1PA,1S0,1T9,1X0,1Y0,1Z0,1ZS,2C5,2FE,2H0,2JH,2V5,2WA,3A2,3C7,3FA,3GD,3H0,3L3,3LD,3NZ,3Q6,3QH,3S1,3ZU,41B,4A3,4BI,4GF,4I2,4KF,4L6,4N1,4P2,4R4,4UF,4ZC,5D1,5JA,5K7,5MC,5PL,5XJ,5ZF,6C2,6E3,6FH,6I1,6K2,6NA,6PC,6Q2,6T1,6YD,7AA,7K1,7L6,7M5,7P4,7UJ,7W0,7X2,7Y0,8AR,8FA,8G4,8GU,8IV,8M1,8N6,8Q3,8RL,8S6,8T9,8TL,8VG,8W1,8WH,8X0,8Y0,8Z5,8ZQ,9AB,9E1,9I5,9JA,9M0,9P4,9S0,9T0,9WJ,9ZX,A8F,AV1,AW4,B01,B0A,C03,DS9,E0A,EA0,EF0,EL5,EM1,ER1,F0A,G15,G1C,GP0,J4J,J89,K8G,KA0,KK3,L0L,L40,N0H,NY0,NZ4,PAA,PDR,Q4H,QG1,QH0,QI6,QJ3,QK1,QN4,QQ3,QR8,QV3,TJ7,U9C,UC5,UD3,UG1,V0A,VF0,VL6,WLB,YOS,ADDGEO_ON",
            },
            {
                model: "Polo",
                prCode: "DER-POLO,AE19UZ,2023,5W5W,WP,00A,0A2,0AT,0B1,0FV,0IJ,0K0,0KA,0N2,0NH,0P5,0TD,0VC,0Y1,0YN,1A5,1AS,1D0,1E5,1EX,1G8,1JE,1KE,1NL,1PE,1S0,1SA,1T9,1TB,1X0,1Y3,1Z0,1ZU,2A8,2C2,2H5,2JD,2PE,2V5,2WA,31K,3A2,3C7,3FB,3GD,3H0,3L3,3NZ,3Q6,3QH,3U1,3ZU,4A3,4AP,4BI,4GF,4I2,4KC,4L6,4N1,4P0,4R4,4U5,4UF,51A,5C0,5D1,5K7,5MJ,5RQ,5SL,5XJ,5ZF,6C2,6E3,6FF,6I2,6K4,6NW,6PC,6Q5,6T1,6YD,7AA,7E0,7K1,7L6,7P4,7UG,7W0,7X2,7Y0,8AR,8FC,8G4,8GU,8IV,8M1,8N6,8Q3,8RL,8S6,8T8,8TC,8VP,8W1,8WA,8X0,8Y1,8Z5,8ZQ,9AK,9E1,9I5,9JA,9M0,9P4,9S0,9T0,9WJ,9ZX,A8H,AV1,AW4,B01,B0A,C34,E0A,EA0,EF1,EL5,EM1,ER1,F0A,G11,G1C,GP1,I11,J2D,K8G,KA1,KK3,L0L,L15,MJ6,N2T,NY0,NZ4,PF3,PJB,Q4P,QG1,QH1,QI6,QJ3,QK1,QN4,QQ3,QR9,QV3,QZ7,RCB,T2C,U5A,U9C,UD3,UG1,V0A,V18,VF2,VL6,VT2,WLD,YOS,ADDGEO_ON",
            },
            {
                model: "ID.BUZZ",
                prCode: "ID.-BUZZ,EBBR1N,2023,9675,IF,01B,0AP,0B1,0BK,0FA,0GA,0NB,0S0,0TA,0VC,0Y1,0YC,1AC,1D0,1EX,1HZ,1J2,1JA,1KB,1LC,1N3,1NL,1PE,1S2,1T9,1X2,2F0,2FB,2JG,2WA,3CA,3FA,3KD,3L3,3MF,3QH,3RC,3U7,3V6,3ZU,41M,4A0,4AF,4DE,4E0,4EE,4G3,4GS,4HM,4I7,4L2,4S4,4UF,5BF,5DA,5F1,5JB,5K7,5LD,5PN,5Q2,5R2,5XJ,6C2,6FG,6I1,6L0,6M0,6T2,6YD,76C,7AA,7G0,7K1,7P0,7PG,7UJ,7W2,7X2,7Y0,8AS,8G0,8GJ,8IT,8J3,8M1,8N5,8Q1,8QJ,8RS,8VG,8Y0,8ZQ,9CU,9E1,9JA,9M0,9P4,9T0,9WJ,9Z0,9ZV,A8C,AV1,B01,B0A,C01,E0A,EA0,EL5,EM1,ER1,ES7,EV0,EW0,F0A,FS5,FT0,G01,G1Z,G9E,GE1,GF0,GG0,GM1,GP0,GW2,IG0,IM0,IN0,IS0,J0H,J75,J9D,K8G,KA0,KB3,KH4,KK3,KS0,L02,L0L,L1F,LH3,LX2,N06,N0B,NI0,NZ2,Q1G,QC1,QE0,QH0,QK1,QQ8,QR9,QV3,QW5,U9G,UD0,V0F,V6H,VF0,VI6,WF2,YV1,ADDGEO_ON",
            },
        ],
    },
};

export const kvps: Array<string> = Object.keys(kvpsToBrandConfigMap);
