
import {Component, Prop, Vue} from "vue-property-decorator";
import {State} from "@/store/decorators";
import {MatchMakerDataEntry, ServiceMarketsCombination} from "@/types";
import {kvpsToBrandConfigMap} from "@/brand-config";

@Component
export default class InfoCard extends Vue {
    @Prop() private matchMakerDataEntry!: MatchMakerDataEntry;
    @State("isUpdatingMatchMakerData") isUpdatingMatchMakerData!: boolean;

    get users(): ServiceMarketsCombination[] {
        return this.matchMakerDataEntry.users;
    }

    private get imageUrl(): string {
        return `data:image/png;base64,${kvpsToBrandConfigMap[this.matchMakerDataEntry.brand].base64Logo}`;
    }

    private get brandName(): string {
        return kvpsToBrandConfigMap[this.matchMakerDataEntry.brand].name;
    }
}
